import { useEffect, useRef, useState } from "react";
import { off, onValue, ref } from "firebase/database";
import { firebaseDb } from "../../../..";
import { usePrevious } from "@technis/ui";

interface PorteData {
  id: number;
  name: string;
  affluenceIn: number;
  affluenceOut: number;
}

const PORTES = [
  { id: 7874, name: "Porte 1" },
  { id: 7875, name: "Porte 2" },
  { id: 7876, name: "Porte 5" },
  { id: 7877, name: "Porte 7" },
  { id: 7878, name: "Porte 8" },
  { id: 7879, name: "Porte 9" },
  { id: 7880, name: "Porte 12" },
  { id: 7881, name: "Porte 13" },
];

export const useFoirePassage = (token: string | undefined) => {
  const [porteData, setPorteData] = useState<PorteData[]>([]);

  const isConnectedRef = useRef(false);

  const url = "/production/liveKPIs/659/2500/passages";

  const startListening = () => {
    isConnectedRef.current = true;
    const liveDataRef = ref(firebaseDb, url);

    onValue(
      liveDataRef,
      (snapshot) => {
        const newValue = snapshot.val();
        const updatedPorteData = PORTES.map((porte) => ({
          ...porte,
          affluenceIn: newValue[porte.id]["AFFLUENCE_IN"] || 0,
          affluenceOut: newValue[porte.id]["AFFLUENCE_OUT"] || 0,
        }));
        setPorteData(updatedPorteData);
      },
      (error) => {
        console.error(error);
        isConnectedRef.current = false;
      },
    );
  };

  const stopListening = () => {
    off(ref(firebaseDb, url));
    isConnectedRef.current = false;
  };

  const prevToken = usePrevious(token);

  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      stopListening();
      startListening();
    } else if (!isConnectedRef.current) {
      startListening();
    }
    return () => {
      if (isConnectedRef.current) stopListening();
    };
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) startListening();
    }, 10000);
    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) stopListening();
    };
  }, []);

  return porteData;
};
