import { LogoPositions, TechnisLogoColor } from "@technis/shared";
import React, { FunctionComponent } from "react";
import Masonry from "react-masonry-component";

import TechnisLogoLight from "../../public/logo-technis-light.svg";
import TechnisLogoDark from "../../public/logo-technis-dark.svg";
import { ZoneWithKpis } from "./ZoneWithKpis";

import { OccupancyEvent } from "../graphql/occupancy.gql";
import { i18n } from "../lang/i18n";
import { translation } from "../lang/translation";
import { ZoneWithMoreData } from "../common/types";
import { useAppConfigQuery } from "../hooks/useConfigQuery";

export const Home: FunctionComponent = () => {
  const { token, data } = useAppConfigQuery();

  if (!token || !data?.initialOccupancyData) return null;

  const { configs, logo, useMasonry, backgroundUrl, technisLogo } = data.initialOccupancyData;

  const enrichZones = (events: OccupancyEvent[]) =>
    events.reduce(
      (acc, { zones, limit, period }) => [
        ...acc,
        ...zones.map((zone) => ({
          ...zone,
          limit,
          period,
        })),
      ],
      [] as ZoneWithMoreData[],
    );

  const zonesConfigs = configs.map((cfg) => ({
    sectionTitle: cfg.sectionTitle,
    zones: enrichZones(cfg.events),
  }));

  const renderZonesWithKpis = (zones: ZoneWithMoreData[]) =>
    zones.map((z) => (
      <ZoneWithKpis
        key={z.id}
        token={token}
        zoneId={z.id}
        periodId={z.period.id}
        name={z.name}
        kpis={z.kpis}
        limit={z.limit}
        textColor={technisLogo.color}
        zone={z}
        customTitle={z.customTitle}
      />
    ));

  const zoneBlocks = zonesConfigs.map((zonesConfig, key) => {
    const zonesToRender = renderZonesWithKpis(zonesConfig.zones);
    return (
      <div className="block" key={key}>
        <div className="sectionTitle">{zonesConfig.sectionTitle}</div>
        {useMasonry ? (
          <Masonry className="masonry" options={{ fitWidth: true }}>
            {zonesToRender}
          </Masonry>
        ) : (
          <div className="container">{zonesToRender}</div>
        )}
      </div>
    );
  });

  return (
    <div className="home-page-wrapper">
      {!!backgroundUrl && <img className="background" src={backgroundUrl} />}
      {!!logo?.url && logo?.position === LogoPositions.TOP && <img className="logo" src={logo?.url} alt="Logo" />}
      <div className="zoneBlockContainer">{zoneBlocks}</div>
      <div className="footer">
        <div className="empty-flex"></div>
        <div className={`technis-footer${technisLogo.isHiden ? "-hide" : ""}`}>
          <span className={`footer-text footer-text${technisLogo.color === TechnisLogoColor.LIGHT ? "-light" : "-dark"}`}>{i18n.t(translation.poweredBy)}</span>
          <img className="footer-logo" src={technisLogo.color === TechnisLogoColor.LIGHT ? TechnisLogoDark : TechnisLogoLight} alt="Technis" />
        </div>
        <div className="logo-bottom-container">
          {!!logo?.url && [LogoPositions.BOTTOM, LogoPositions.BOTTOM_RIGHT].includes(logo?.position) && <img className="logo-bottom" src={logo?.url} alt="Logo" />}
        </div>
      </div>
    </div>
  );
};
