import React from "react";

import { IberiaPage } from "./components/IberiaPage";
import { DaliMap } from "./components/DaliMap";
import { VelazquezMap } from "./components/VelazquezMap";
import { VelazquezMap2 } from "./components/VelazquezMap2";
import { useVisionData } from "../../../hooks/useVisionData";
import { RetailMetrics } from "@technis/shared";

const spec = {
  dali: {
    site: 1514,
    zones: {
      south: { zones: [2401], capacity: 176 },
      central: { zones: [2402], capacity: 110 },
      north: { zones: [2400], capacity: 176 },
    },
  },
  velazquez: {
    site: 2021,
    zones: {
      vinoteca: { zones: [3907], capacity: 84 },
      south: { zones: [3905, 3906], capacity: 124 },
      central: { zones: [4845], capacity: 136 },
      north: { zones: [3909, 3910], capacity: 100 },
      restaurante: { zones: [3911, 3912], capacity: 107 },
    },
  },
} as const;

// Note: OCCUPANCY is not available for Velazquez as the capacity is not set AND multiple technis zones are used for the same physical zone.
const getConfig = (installationId: number) => ({ organizationId: 706, installationId, metric: RetailMetrics.INSIDE });

const occupancy = (data: (number | undefined)[], capacity: number) => {
  const validData = data.filter((v): v is number => v !== undefined);

  if (validData.length === 0) {
    return undefined;
  }

  return Math.floor((validData.reduce((acc: number, curr: number) => acc + curr, 0) / capacity) * 100);
};

export const DaliPage = () => {
  const { site, zones } = spec.dali;
  const config = getConfig(site);

  const data = {
    central: zones.central.zones.map((zoneId) => useVisionData({ ...config, zoneId })),
    south: zones.south.zones.map((zoneId) => useVisionData({ ...config, zoneId })),
    north: zones.north.zones.map((zoneId) => useVisionData({ ...config, zoneId })),
  } satisfies Record<keyof typeof zones, (number | undefined)[]>;

  const occupancies = {
    central: occupancy(data.central, zones.central.capacity),
    south: occupancy(data.south, zones.south.capacity),
    north: occupancy(data.north, zones.north.capacity),
  } satisfies Record<keyof typeof zones, number | undefined>;

  return (
    <IberiaPage logo="dali">
      <DaliMap occupancies={occupancies} />
    </IberiaPage>
  );
};

export const VelazquezPage = () => {
  const { site, zones } = spec.velazquez;
  const config = getConfig(site);

  const data = {
    vinoteca: zones.vinoteca.zones.map((zoneId) => useVisionData({ ...config, zoneId })),
    south: zones.south.zones.map((zoneId) => useVisionData({ ...config, zoneId })),
    central: zones.central.zones.map((zoneId) => useVisionData({ ...config, zoneId })),
    north: zones.north.zones.map((zoneId) => useVisionData({ ...config, zoneId })),
    restaurante: zones.restaurante.zones.map((zoneId) => useVisionData({ ...config, zoneId })),
  } satisfies Record<keyof typeof zones, (number | undefined)[]>;

  const occupancies = {
    vinoteca: occupancy(data.vinoteca, zones.vinoteca.capacity),
    south: occupancy(data.south, zones.south.capacity),
    central: occupancy(data.central, zones.central.capacity),
    north: occupancy(data.north, zones.north.capacity),
    restaurante: occupancy(data.restaurante, zones.restaurante.capacity),
  } satisfies Record<keyof typeof zones, number | undefined>;

  return (
    <IberiaPage logo="velazquez">
      <VelazquezMap occupancies={occupancies} />
    </IberiaPage>
  );
};

export const VelazquezPage2 = () => {
  const { site, zones } = spec.velazquez;
  const config = getConfig(site);

  const data = {
    vinoteca: zones.vinoteca.zones.map((zoneId) => useVisionData({ ...config, zoneId })),
    south: zones.south.zones.map((zoneId) => useVisionData({ ...config, zoneId })),
    central: zones.central.zones.map((zoneId) => useVisionData({ ...config, zoneId })),
    north: zones.north.zones.map((zoneId) => useVisionData({ ...config, zoneId })),
    restaurante: zones.restaurante.zones.map((zoneId) => useVisionData({ ...config, zoneId })),
  } satisfies Record<keyof typeof zones, (number | undefined)[]>;

  const occupancies = {
    vinoteca: occupancy(data.vinoteca, zones.vinoteca.capacity),
    south: occupancy(data.south, zones.south.capacity),
    central: occupancy(data.central, zones.central.capacity),
    north: occupancy(data.north, zones.north.capacity),
    restaurante: occupancy(data.restaurante, zones.restaurante.capacity),
  } satisfies Record<keyof typeof zones, number | undefined>;

  return (
    <IberiaPage logo="velazquez">
      <VelazquezMap2 occupancies={occupancies} />
    </IberiaPage>
  );
};
