import { useEffect, useRef, useState } from "react";
import { off, onValue, ref } from "firebase/database";
import { firebaseDb } from "../../../..";

const FOIRE_DU_TRONE = 5114;

function usePrevious(value: string | undefined): string | undefined {
  const ref = useRef<string | undefined>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useFoireZone = (token: string | undefined) => {
  const [insideKpi, setInsideKpi] = useState<number>(0);
  const [affluenceInKpi, setAffluenceInKpi] = useState<number>(0);

  const isConnectedRef = useRef(false);

  const url = "/production/liveKPIs/659/2500/zones";

  const startListening = () => {
    isConnectedRef.current = true;
    const liveDataRef = ref(firebaseDb, url);

    onValue(
      liveDataRef,
      (snapshot) => {
        const newValue = snapshot.val();

        const currentInsideKpi = newValue[FOIRE_DU_TRONE]["INSIDE"];
        setInsideKpi(currentInsideKpi);

        const currentAffluenceInKpi = newValue[FOIRE_DU_TRONE]["AFFLUENCE_IN"];
        setAffluenceInKpi(currentAffluenceInKpi);
      },
      (error) => {
        console.error(error);
        isConnectedRef.current = false;
      },
    );
  };

  const stopListening = () => {
    off(ref(firebaseDb, url));
    isConnectedRef.current = false;
  };

  const prevToken = usePrevious(token);

  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      stopListening();
      startListening();
    } else if (!isConnectedRef.current) {
      startListening();
    }

    return () => {
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) {
        startListening();
      }
    }, 10000);

    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, []);

  return { insideKpi, affluenceInKpi };
};
