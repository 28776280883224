import React from "react";
import { MapData, ZoneData } from "../data/zones";

import { MapViewColorimetry } from "@technis/shared";
import { DataView } from "./DataView";
import { MapView } from "@technis/ui";

export const Home: React.FC<{ zones: ZoneData[]; colorimetry: MapViewColorimetry[]; selectedMap: MapData | null }> = ({ zones, colorimetry, selectedMap }) => (
  <>
    <div className="makkah__map">
      {selectedMap?.url && (
        <MapView
          key={selectedMap.url}
          items={zones.map(({ inside, name, id, capacity }) => ({
            zoneId: id,
            value: capacity && inside ? (inside / capacity) * 100 : 0,
            rawValue: capacity && inside ? (inside / capacity) * 100 : 0,
            colorimetry: colorimetry,
            tooltip: {
              content: (
                <div className="flex flex-col gap-2">
                  <div>
                    <strong>{name}</strong>: {inside}
                  </div>
                </div>
              ),
            },
          }))}
          layers={[
            {
              name: selectedMap.caption,
              url: selectedMap.url,
              // backgroundColor: "#F5F6F9",
            },
          ]}
          // colorimetry={mapviewConfig.colorimetry}
          tooltipConfig={{
            mode: "hover",
            useTitle: true,
            useLabel: false,
            useUnit: false,
            useIcon: false,
            useValue: false,
          }}
          colorimetryConfig={{
            shouldLegendAlwaysVisible: true,
            useColorimetryLegend: true,
            position: "topLeft",
            colorimetry: colorimetry,
          }}
          version="2"
          showList={false}
        />
      )}
    </div>
    <DataView zones={zones} colorimetry={colorimetry} />
  </>
);
