import { ScaleItem } from "../../../../hooks/useScale";

export const COLORS = {
  CROWDED: {
    zoneColor: "#D7192D",
    textColor: "#D7192D",
  },
  MEDIUM: {
    zoneColor: "#F49C1C",
    textColor: "#F49C1C",
  },
  LOW: {
    zoneColor: "#4BC59C",
    textColor: "#4BC59C",
  },
  EMPTY: {
    zoneColor: "transparent",
    textColor: "white",
  },
};

export const COLOR_SCALES: ScaleItem[] = [
  {
    value: [0, 33],
    ...COLORS.LOW,
  },
  {
    value: [34, 66],
    ...COLORS.MEDIUM,
  },
  {
    value: [67, Infinity],
    ...COLORS.CROWDED,
  },
];
