import React, { useState } from "react";
import { ZoneData } from "../data/zones";
import classNames from "classnames";
import point from "../assets/point.svg";
import { MapViewColorimetry } from "@technis/shared";
import { DataColumn } from "./DataColumn";

const ZONE_PER_COLUMN = 15;

export const DataView: React.FC<{ zones: ZoneData[]; colorimetry: MapViewColorimetry[] }> = ({ zones, colorimetry }) => {
  const [viewType, setViewType] = useState<"percent" | "number">("percent");

  const columnNumber = Math.ceil(zones.length / ZONE_PER_COLUMN);

  const columns = Array.from({ length: columnNumber }, (_, index) => zones.slice(index * ZONE_PER_COLUMN, (index + 1) * ZONE_PER_COLUMN));

  return (
    <div className="makkah__data-view">
      <div className="makkah__data-view__header">
        <div className="makkah__data-view__header__title">
          <img src={point} alt="icon" />
          Data in real time
        </div>
        <div className="makkah__data-view__view-selector">
          <button
            className={classNames("makkah__data-view__view-selector__button", {
              "makkah__data-view__view-selector__button--active": viewType === "percent",
            })}
            onClick={() => setViewType("percent")}
          >
            %
          </button>
          <button
            className={classNames("makkah__data-view__view-selector__button", {
              "makkah__data-view__view-selector__button--active": viewType === "number",
            })}
            onClick={() => setViewType("number")}
          >
            123
          </button>
        </div>
      </div>
      <div className="makkah__data-view__content">
        {columns.map((column, index) => (
          <DataColumn key={index} viewType={viewType} zones={column} colorimetry={colorimetry} />
        ))}
      </div>
    </div>
  );
};
