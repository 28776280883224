import React, { useMemo, useState } from "react";
import {
  MAP_N1_ZONES,
  MAP_N2_ZONES,
  MAP_N3_ZONES,
  MAP_N4_ZONES,
  MAP_N5_ZONES,
  MAP_N6_ZONES,
  MAP_N7_ZONES,
  MAP_N8_ZONES,
  MAP_N9_ZONES,
  MAP_N10_ZONES,
  MAP_N11_ZONES,
  useZones,
  MapData,
} from "./data/zones";
import logo from "./assets/logo.svg";
import { MapViewColorimetry } from "@technis/shared";
import { Home } from "./components/Home";
import { Counts } from "./components/Counts";
import classNames from "classnames";

const COLORIMENTRY: MapViewColorimetry[] = [
  {
    limits: [0, 20],
    color: "#FFFFCC",
    text: "0-20%",
  },
  {
    limits: [20, 40],
    color: "#FFEDA0",
    text: "20-40%",
  },
  {
    limits: [40, 60],
    color: "#FEB24C",
    text: "40-60%",
  },
  {
    limits: [60, 80],
    color: "#F03B20",
    text: "60-80%",
  },
  {
    limits: [80, 100],
    color: "#BD0026",
    text: "80-100%",
  },
];

const MAP_LIST: MapData[] = [
  { caption: "صحن المطاف", zones: MAP_N1_ZONES, url: "https://storage.googleapis.com/technis-counting-retail-resources/dashboards/makkah/map-f-1.svg" },
  { caption: "المطاف الدور الارضي", zones: MAP_N2_ZONES, url: "https://storage.googleapis.com/technis-counting-retail-resources/dashboards/makkah/map-f-2.svg" },
  { caption: "المطاف الدور الاول", zones: MAP_N3_ZONES, url: "https://storage.googleapis.com/technis-counting-retail-resources/dashboards/makkah/map-f-3.svg" },
  { caption: "سطح المطاف", zones: MAP_N4_ZONES, url: "https://storage.googleapis.com/technis-counting-retail-resources/dashboards/makkah/map-f-4.svg" },
  { caption: "الدور الاول ميزانين", zones: MAP_N5_ZONES, url: "https://storage.googleapis.com/technis-counting-retail-resources/dashboards/makkah/map-f-5.svg" },
  { caption: "التوسعة السعودية الثالثة: الدور الثاني", zones: MAP_N6_ZONES, url: "https://storage.googleapis.com/technis-counting-retail-resources/dashboards/makkah/map-f-6.svg" },
  { caption: "الدور الثاني ميزانين", zones: MAP_N7_ZONES, url: "https://storage.googleapis.com/technis-counting-retail-resources/dashboards/makkah/map-f-7.svg" },
  { caption: "الدور الاول (المصاطب)", zones: MAP_N8_ZONES, url: "https://storage.googleapis.com/technis-counting-retail-resources/dashboards/makkah/map-f-8.svg" },
  { caption: "الدور الثاني (المصاطب)", zones: MAP_N9_ZONES, url: "https://storage.googleapis.com/technis-counting-retail-resources/dashboards/makkah/map-f-9.svg" },
  { caption: "الدور الثالث (المصاطب)", zones: MAP_N10_ZONES, url: "https://storage.googleapis.com/technis-counting-retail-resources/dashboards/makkah/map-f-10.svg" },
  { caption: "الدور الرابع (المصاطب)", zones: MAP_N11_ZONES, url: "https://storage.googleapis.com/technis-counting-retail-resources/dashboards/makkah/map-f-11.svg" },
];

export const MakkahPage = () => {
  const [selectedMap, setSelectedMap] = useState<MapData>(MAP_LIST[0]);

  const selectedZones = useMemo(() => selectedMap?.zones ?? [], [selectedMap]);

  const zones = useZones({ zoneIds: selectedZones.map((zone) => zone.id) });

  const isCountPage = new URLSearchParams(window.location.search).has("counts");

  return (
    <div className="makkah__page">
      <div className="makkah__header">
        <a href="/" className="makkah__header__back">
          <img src={logo} alt="logo" />
        </a>

        <div className="makkah__header__title">
          <h1>Zones Occupancy</h1>
          <h2>سعة مصليات موسم رمضان</h2>
        </div>

        <div className="makkah__selector">
          <label htmlFor="map-selector">Select: </label>
          <select id="map-selector" onChange={(e) => setSelectedMap(MAP_LIST[parseInt(e.target.value)])}>
            {MAP_LIST.map((map, index) => (
              <option value={index} key={map.caption}>
                {map.caption}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={classNames("makkah__content", { home: !isCountPage })}>
        {isCountPage ? <Counts zones={zones} selectedMap={selectedMap} /> : <Home selectedMap={selectedMap} zones={zones} colorimetry={COLORIMENTRY} />}
      </div>
    </div>
  );
};
