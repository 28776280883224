import React from "react";
import { FCC } from "../../../../common/types";
import Logo from "../assets/Iberia-logo.svg";
import Dali from "../assets/Dali-logo.svg";
import Velazquez from "../assets/Velazquez-logo.svg";

const LogoMap = {
  dali: Dali,
  velazquez: Velazquez,
};

export const IberiaPage: FCC<{ logo: keyof typeof LogoMap }> = ({ logo, children }) => (
  <div className="iberia">
    <div className="iberia__page">
      <div className="iberia__header">
        <img src={LogoMap[logo]} className="iberia__logo iberia__logo--site" />
        <img src={Logo} alt="logo" className="iberia__logo" />
      </div>
      <div className="iberia__content">{children}</div>
    </div>
  </div>
);
