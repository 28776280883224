import { FCC } from "../common/types";
import React, { useEffect } from "react";

import { useAppConfigQuery } from "../hooks/useConfigQuery";
import { setLanguage, SupportedLanguages } from "../lang/i18n";
import { UserLanguage } from "@technis/shared";

export const AppShell: FCC = ({ children }) => {
  const { data } = useAppConfigQuery();

  useEffect(() => {
    const occupancyData = data?.initialOccupancyData;

    if (occupancyData) {
      const { title, faviconUrl, lang } = occupancyData;
      const favicon = document.getElementById("favicon") as HTMLLinkElement;

      if (title) {
        document.title = title;
      }
      if (faviconUrl) {
        favicon.href = faviconUrl;
      }
      if (lang === UserLanguage.FR) {
        setLanguage(SupportedLanguages.FRENCH);
      } else {
        setLanguage(SupportedLanguages.ENGLISH);
      }
    }
  }, [data?.initialOccupancyData]);

  return <>{children}</>;
};
