import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import React, { useEffect, useState } from "react";
import { ApolloProvider } from "react-apollo";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { apollo } from "./api/apollo";
import { persistor, store } from "./redux/store";
import { ThemeContextProvider } from "./components/common/Theme";
import { BrowserRouter } from "react-router-dom";
import { MainRoutes } from "./components/routing/MainRoutes";
import { login } from "./redux/auth/auth.actions";
import { CUSTOM_PAGES, getCustomPage } from "./components/custom";
import { ZoomProvider } from "./components/common/ZoomProvider";
import { ErrorComponent } from "./components/common/ErrorComponent";
import { Loader } from "@technis/ui";
import { ErrorBoundary } from "./components/common/ErrorBoundary";
import { AppShell } from "./components/Appshell";

require("./styles/index.scss");

const { initClient } = apollo;

const configFirebase = {
  apiKey: process.env.FIREBASE_AUTH_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  storageBucket: process.env.GCLOUD_STORAGE_BUCKET,
  appId: process.env.FIREBASE_APP_ID,
  projectId: process.env.GCLOUD_PROJECT_ID,
};
export const firebaseApp = initializeApp(configFirebase);
export const firebaseDb = getDatabase(firebaseApp);

const LOCAL_DOMAIN_OVERRIDE = CUSTOM_PAGES.MAKKAH;

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [subdomain] = window.location.hostname.split(".");
  useEffect(() => {
    initClient()
      // TODO : remove this
      .then(login(subdomain === "localhost" ? LOCAL_DOMAIN_OVERRIDE : subdomain))
      .then(() => setIsLoading(false))
      .catch((e: Error) => {
        console.log(e);
        setErrorMsg(e.message);
        setIsLoading(false);
      });
  }, []);

  const isCustomPage = Object.values(CUSTOM_PAGES).includes((subdomain === "localhost" ? LOCAL_DOMAIN_OVERRIDE : subdomain) as CUSTOM_PAGES);

  const Page = isCustomPage ? getCustomPage(subdomain === "localhost" ? LOCAL_DOMAIN_OVERRIDE : subdomain) : MainRoutes;

  if (errorMsg) {
    return (
      <ThemeContextProvider setRoot>
        <ErrorComponent errorDetails={errorMsg} />
      </ThemeContextProvider>
    );
  }

  return !isLoading ? (
    <ApolloProvider client={apollo.client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeContextProvider setRoot>
            <BrowserRouter>
              <ZoomProvider>
                <ErrorBoundary>
                  <AppShell>
                    <Page />
                  </AppShell>
                </ErrorBoundary>
              </ZoomProvider>
            </BrowserRouter>
          </ThemeContextProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  ) : (
    <ThemeContextProvider setRoot>
      <div className="loadingContainer">
        <Loader />
      </div>
    </ThemeContextProvider>
  );
};

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
