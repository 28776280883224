import React from "react";
import { MapData, updateZone, ZoneData } from "../data/zones";
import location from "../assets/location.svg";

export const Counts: React.FC<{ zones: ZoneData[]; selectedMap: MapData | null }> = ({ zones, selectedMap }) => (
  <div className="makkah__counts">
    <div className="makkah__counts__header">
      <div className="makkah__counts__header__title">
        {selectedMap?.caption}

        <div className="makkah__counts__header__zone-count">{zones.length} zones</div>
      </div>

      <div className="makkah__counts__header__description">Edit the visitors capacity and/or visitors in inside by each zone.</div>
    </div>
    <div className="makkah__counts__table-wrapper">
      <table className="makkah__counts__table" cellSpacing={0} cellPadding={0}>
        <thead>
          <tr>
            <th>Zone</th>
            <th>
              Visitors in <span>(datas on real time)</span>
            </th>
            <th>Capacity of visitors</th>
          </tr>
        </thead>
        <tbody>
          {zones.map((zone) => (
            <tr key={zone.id} className="makkah__counts__table__row">
              <td>
                <div className="makkah__counts__table__row-part">
                  <div className="makkah__counts__table__name">
                    <img src={location} alt="location" />
                    {zone.name}
                  </div>
                </div>
              </td>
              <td>
                <div className="makkah__counts__table__row-part">
                  <input
                    type="number"
                    value={zone.inside ?? ""}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value > (zone.capacity ?? 0)) {
                        updateZone(zone.id, zone.capacity ?? 0, "inside");
                      } else {
                        updateZone(zone.id, Number(e.target.value), "inside");
                      }
                    }}
                    {...(zone.capacity ? { max: zone.capacity } : {})}
                  />
                </div>
              </td>
              <td>
                <div className="makkah__counts__table__row-part">
                  <input
                    type="number"
                    value={zone.capacity ?? ""}
                    onChange={(e) => {
                      updateZone(zone.id, Number(e.target.value), "capacity");
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);
