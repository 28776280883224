/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { ZoneData } from "../data/zones";

import { MapViewColorimetry } from "@technis/shared";
import { isNumber } from "lodash";

const Item = ({ zone, colorimetry, viewType }: { zone: ZoneData; colorimetry: MapViewColorimetry[]; viewType: "percent" | "number" }) => {
  const canCalculate = isNumber(zone.capacity!) && isNumber(zone.inside!);

  if (!canCalculate) {
    return (
      <div className="makkah__data-column__content__item">
        <div className="makkah__data-column__content__item__name">{zone.name}</div>
        <div>{}</div>
      </div>
    );
  }

  const percentage = Math.ceil((zone.inside! / zone.capacity!) * 100);
  const isPercentage = viewType === "percent";

  const color = colorimetry.find(({ limits }) => limits?.[0] !== undefined && limits?.[1] !== undefined && limits[0]! <= percentage && limits[1]! >= percentage)?.color;

  return (
    <div className="makkah__data-column__content__item">
      <div className="makkah__data-column__content__item__name">{zone.name}</div>
      <div className="makkah__data-column__content__item__value">
        <div className="makkah__data-column__content__item__value__number">
          <span>{isPercentage ? percentage : zone.inside}</span>
          {isPercentage ? " %" : ` / ${zone.capacity}`}
        </div>
        <div className="makkah__data-column__content__item__progress">
          <div className="makkah__data-column__content__item__progress__bar" style={{ width: `${percentage}%`, ...(color && { backgroundColor: color }) }} />
        </div>
      </div>
    </div>
  );
};

export const DataColumn: React.FC<{ viewType: "percent" | "number"; zones: ZoneData[]; colorimetry: MapViewColorimetry[] }> = ({ viewType, zones, colorimetry }) => (
  <div className="makkah__data-column">
    <div className="makkah__data-column__header">
      <p>
        <span>Zone</span>
      </p>
      <p>
        <span>Visitors in</span> vs <span>limit</span>
      </p>
    </div>
    <div className="makkah__data-column__content">
      {zones.map((zone) => (
        <Item key={zone.id} zone={zone} colorimetry={colorimetry} viewType={viewType} />
      ))}
    </div>
  </div>
);
