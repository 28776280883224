import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { initialData, InitialDataResult } from "../graphql/occupancy.gql";

const INITIAL_DATA_POLL_INTERVAL = 30000;

export const useAppConfigQuery = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const { data } = useQuery<InitialDataResult>(initialData, {
    fetchPolicy: "cache-and-network",
    skip: !token,
    pollInterval: INITIAL_DATA_POLL_INTERVAL,
  });

  return { token, data };
};
