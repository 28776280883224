import { off, onValue, ref, set } from "firebase/database";
import { firebaseDb } from "../../../..";
import { useEffect, useRef, useState } from "react";
import { usePrevious } from "../../../../hooks";
import { DISCONNECT_CHECK_INTERVAL } from "../../../../common/constants";
import { isDefined, Maybe } from "@technis/shared";

/* 
  A = 1
  B = 2
  C = 3
  D = 4
  E = 5
  F = 6
  G = 7
  H = 8
  I = 9
  J = 10
  K = 11
  L = 12
  M = 13
  N = 14
  O = 15
  P = 16
  Q = 17
  R = 18
  S = 19
  T = 20
  U = 21
  V = 22
  W = 23
  X = 24
  Y = 25
  Z = 26
*/

export const MAP_N1_ZONES = [
  {
    name: "T-1-1",
    capacity: 262,
    id: 2011,
  },
  {
    name: "T-1-2",
    capacity: 264,
    id: 2012,
  },
  {
    name: "T-1-3",
    capacity: 265,
    id: 2013,
  },
  {
    name: "T-1-4",
    capacity: 262,
    id: 2014,
  },
  {
    name: "T-1-5",
    capacity: 784,
    id: 2015,
  },
  {
    name: "T-1-6",
    capacity: 260,
    id: 2016,
  },
  {
    name: "T-1-7",
    capacity: 264,
    id: 2017,
  },
  {
    name: "T-1-8",
    capacity: 261,
    id: 2018,
  },
  {
    name: "T-1-9",
    capacity: 780,
    id: 2019,
  },
  {
    name: "T-1-10",
    capacity: 261,
    id: 20110,
  },
  {
    name: "T-1-11",
    capacity: 260,
    id: 20111,
  },
  {
    name: "T-1-12",
    capacity: 218,
    id: 20112,
  },
  {
    name: "T-1-13",
    capacity: 236,
    id: 20113,
  },
  {
    name: "T-1-14",
    capacity: 728,
    id: 20114,
  },
  {
    name: "T-1-15",
    capacity: 1190,
    id: 20115,
  },
  {
    name: "T-1-16",
    capacity: 235,
    id: 20116,
  },
  {
    name: "T-1-17",
    capacity: 268,
    id: 20117,
  },
  {
    name: "T-1-18",
    capacity: 178,
    id: 20118,
  },
  {
    name: "T-1-19",
    capacity: 150,
    id: 20119,
  },
  {
    name: "T-1-20",
    capacity: 182,
    id: 20120,
  },
  {
    name: "T-1-21",
    capacity: 218,
    id: 20121,
  },
  {
    name: "T-1-22",
    capacity: 630,
    id: 20122,
  },
  {
    name: "T-1-23",
    capacity: 326,
    id: 20123,
  },
  {
    name: "T-1-24",
    capacity: 400,
    id: 20124,
  },
  {
    name: "M-1-1",
    capacity: 1945,
    id: 1311,
  },
  {
    name: "M-1-2",
    capacity: 362,
    id: 1312,
  },
  {
    name: "M-1-3",
    capacity: 1100,
    id: 1313,
  },
  {
    name: "F-1-1",
    capacity: 362,
    id: 611,
  },
  {
    name: "F-1-2",
    capacity: 1141,
    id: 612,
  },
  {
    name: "F-1-3",
    capacity: 1777,
    id: 613,
  },
  {
    name: "F-1-4",
    capacity: 1272,
    id: 614,
  },
  {
    name: "F-1-5",
    capacity: 872,
    id: 615,
  },
  {
    name: "F-1-6",
    capacity: 469,
    id: 616,
  },
];

export const MAP_N2_ZONES = [
  {
    name: "T-2-1",
    capacity: 826,
    id: 2021,
  },
  {
    name: "T-2-2",
    capacity: 496,
    id: 2022,
  },
  {
    name: "T-2-3",
    capacity: 248,
    id: 2023,
  },
  {
    name: "T-2-4",
    capacity: 1572,
    id: 2024,
  },
  {
    name: "T-2-5",
    capacity: 448,
    id: 2025,
  },
  {
    name: "T-2-6",
    capacity: 210,
    id: 2026,
  },
  {
    name: "T-2-7",
    capacity: 685,
    id: 2027,
  },
  {
    name: "T-2-8",
    capacity: 685,
    id: 2028,
  },
  {
    name: "T-2-9",
    capacity: 760,
    id: 2029,
  },
  {
    name: "T-2-10",
    capacity: 760,
    id: 20210,
  },
  {
    name: "T-2-11",
    capacity: 446,
    id: 20211,
  },
  {
    name: "T-2-12",
    capacity: 220,
    id: 20212,
  },
  {
    name: "T-2-13",
    capacity: 570,
    id: 20213,
  },
  {
    name: "T-2-14",
    capacity: 570,
    id: 20214,
  },

  {
    name: "A-1-1",
    capacity: 613,
    id: 111,
  },
  {
    name: "A-1-2",
    capacity: 476,
    id: 112,
  },
  {
    name: "A-1-3",
    capacity: 790,
    id: 113,
  },
  {
    name: "A-1-4",
    capacity: 948,
    id: 114,
  },
  {
    name: "A-1-5",
    capacity: 1093,
    id: 115,
  },
  {
    name: "A-1-6",
    capacity: 612,
    id: 116,
  },
  {
    name: "A-1-7",
    capacity: 472,
    id: 117,
  },
  {
    name: "A-1-8",
    capacity: 597,
    id: 118,
  },
  {
    name: "A-1-9",
    capacity: 952,
    id: 119,
  },
  {
    name: "A-1-10",
    capacity: 1093,
    id: 1110,
  },
  {
    name: "A-1-11",
    capacity: 2604,
    id: 1111,
  },
  {
    name: "A-1-12",
    capacity: 1724,
    id: 1112,
  },
  {
    name: "A-1-13",
    capacity: 5945,
    id: 1113,
  },
  {
    name: "A-1-14",
    capacity: 2566,
    id: 1114,
  },
  {
    name: "A-1-15",
    capacity: 1720,
    id: 1115,
  },
  {
    name: "A-1-16",
    capacity: 614,
    id: 1116,
  },
  {
    name: "A-1-17",
    capacity: 784,
    id: 1117,
  },
  {
    name: "A-1-18",
    capacity: 949,
    id: 1118,
  },
  {
    name: "A-1-19",
    capacity: 1060,
    id: 1119,
  },
  {
    name: "A-1-20",
    capacity: 880,
    id: 1120,
  },
  {
    name: "A-1-21",
    capacity: 786,
    id: 1121,
  },
  {
    name: "A-1-22",
    capacity: 948,
    id: 1122,
  },
  {
    name: "A-1-23",
    capacity: 1052,
    id: 1123,
  },

  {
    name: "F-2-1",
    capacity: 2732,
    id: 621,
  },
  {
    name: "F-2-2",
    capacity: 2732,
    id: 622,
  },
  {
    name: "F-2-3",
    capacity: 3649,
    id: 623,
  },
  {
    name: "F-2-4",
    capacity: 3649,
    id: 624,
  },
  {
    name: "F-2-5",
    capacity: 2734,
    id: 625,
  },
  {
    name: "F-2-6",
    capacity: 2734,
    id: 626,
  },
  {
    name: "F-2-7",
    capacity: 3344,
    id: 627,
  },
  {
    name: "F-2-8",
    capacity: 3344,
    id: 628,
  },
];

export const MAP_N3_ZONES = [
  {
    name: "T-3-1",
    capacity: 637,
    id: 2031,
  },
  {
    name: "T-3-2",
    capacity: 812,
    id: 2032,
  },
  {
    name: "T-3-3",
    capacity: 672,
    id: 2033,
  },
  {
    name: "T-3-4",
    capacity: 1044,
    id: 2034,
  },
  {
    name: "T-3-5",
    capacity: 604,
    id: 2035,
  },
  {
    name: "T-3-6",
    capacity: 600,
    id: 2036,
  },

  {
    name: "A-2-1",
    capacity: 613,
    id: 121,
  },
  {
    name: "A-2-2",
    capacity: 790,
    id: 122,
  },
  {
    name: "A-2-3",
    capacity: 948,
    id: 123,
  },
  {
    name: "A-2-4",
    capacity: 1093,
    id: 124,
  },
  {
    name: "A-2-5",
    capacity: 612,
    id: 125,
  },
  {
    name: "A-2-6",
    capacity: 796,
    id: 126,
  },
  {
    name: "A-2-7",
    capacity: 952,
    id: 127,
  },
  {
    name: "A-2-8",
    capacity: 1093,
    id: 128,
  },
  {
    name: "A-2-9",
    capacity: 614,
    id: 129,
  },
  {
    name: "A-2-10",
    capacity: 784,
    id: 1210,
  },
  {
    name: "A-2-11",
    capacity: 949,
    id: 1211,
  },
  {
    name: "A-2-12",
    capacity: 1060,
    id: 1212,
  },
  {
    name: "A-2-13",
    capacity: 613,
    id: 1213,
  },
  {
    name: "A-2-14",
    capacity: 786,
    id: 1214,
  },
  {
    name: "A-2-15",
    capacity: 948,
    id: 1215,
  },
  {
    name: "A-2-16",
    capacity: 1052,
    id: 1216,
  },

  {
    name: "F-3-1",
    capacity: 445,
    id: 631,
  },
  {
    name: "F-3-2",
    capacity: 2309,
    id: 632,
  },
  {
    name: "F-3-3",
    capacity: 2785,
    id: 633,
  },
  {
    name: "F-3-4",
    capacity: 366,
    id: 634,
  },
  {
    name: "F-3-5",
    capacity: 2208,
    id: 635,
  },
  {
    name: "F-3-6",
    capacity: 2720,
    id: 636,
  },
  {
    name: "F-3-7",
    capacity: 1717,
    id: 637,
  },
  {
    name: "F-3-8",
    capacity: 458,
    id: 638,
  },
];

export const MAP_N4_ZONES = [
  {
    name: "T-4-1",
    capacity: 1441,
    id: 2041,
  },
  {
    name: "T-4-2",
    capacity: 1560,
    id: 2042,
  },
  {
    name: "T-4-3",
    capacity: 1908,
    id: 2043,
  },
  {
    name: "T-4-4",
    capacity: 1225,
    id: 2044,
  },
  {
    name: "T-4-5",
    capacity: 1217,
    id: 2045,
  },
  {
    name: "T-4-6",
    capacity: 1773,
    id: 2046,
  },
  {
    name: "T-4-7",
    capacity: 698,
    id: 2047,
  },
  {
    name: "T-4-8",
    capacity: 2109,
    id: 2048,
  },
  {
    name: "T-4-9",
    capacity: 1616,
    id: 2049,
  },
  {
    name: "T-4-10",
    capacity: 1937,
    id: 20410,
  },
  {
    name: "T-4-11",
    capacity: 1319,
    id: 20411,
  },
  {
    name: "T-4-12",
    capacity: 1288,
    id: 20412,
  },
  {
    name: "M-4-1",
    capacity: 942,
    id: 1341,
  },
  {
    name: "M-4-2",
    capacity: 1210,
    id: 1342,
  },
  {
    name: "M-4-3",
    capacity: 1492,
    id: 1343,
  },

  {
    name: "F-4-1",
    capacity: 2260,
    id: 641,
  },
  {
    name: "F-4-2",
    capacity: 1760,
    id: 642,
  },
  {
    name: "F-4-3",
    capacity: 1748,
    id: 643,
  },
  {
    name: "F-4-4",
    capacity: 3332,
    id: 644,
  },
  {
    name: "F-4-5",
    capacity: 2081,
    id: 645,
  },
  {
    name: "F-4-6",
    capacity: 2044,
    id: 646,
  },
];

export const MAP_N5_ZONES = [
  {
    name: "A-3-1",
    capacity: 809,
    id: 131,
  },
  {
    name: "A-3-2",
    capacity: 948,
    id: 132,
  },
  {
    name: "A-3-3",
    capacity: 1141,
    id: 133,
  },
  {
    name: "A-3-4",
    capacity: 1292,
    id: 134,
  },
  {
    name: "A-3-5",
    capacity: 750,
    id: 135,
  },
  {
    name: "A-3-6",
    capacity: 942,
    id: 136,
  },
  {
    name: "A-3-7",
    capacity: 1138,
    id: 137,
  },
  {
    name: "A-3-8",
    capacity: 1292,
    id: 138,
  },
];

export const MAP_N6_ZONES = [
  {
    name: "A-4-1",
    capacity: 1870,
    id: 141,
  },
  {
    name: "A-4-2",
    capacity: 1025,
    id: 142,
  },
  {
    name: "A-4-3",
    capacity: 1188,
    id: 143,
  },
  {
    name: "A-4-4",
    capacity: 1230,
    id: 144,
  },
  {
    name: "A-4-5",
    capacity: 993,
    id: 145,
  },
  {
    name: "A-4-6",
    capacity: 1157,
    id: 146,
  },
  {
    name: "A-4-7",
    capacity: 1177,
    id: 147,
  },
  {
    name: "A-4-8",
    capacity: 1870,
    id: 148,
  },
  {
    name: "A-4-9",
    capacity: 969,
    id: 149,
  },
  {
    name: "A-4-10",
    capacity: 1140,
    id: 1410,
  },
  {
    name: "A-4-11",
    capacity: 1280,
    id: 1411,
  },
  {
    name: "A-4-12",
    capacity: 962,
    id: 1412,
  },
  {
    name: "A-4-13",
    capacity: 1137,
    id: 1413,
  },
  {
    name: "A-4-14",
    capacity: 1278,
    id: 1414,
  },
];

export const MAP_N7_ZONES = [
  {
    name: "A-5-1",
    capacity: 1451,
    id: 151,
  },
  {
    name: "A-5-2",
    capacity: 912,
    id: 152,
  },
  {
    name: "A-5-3",
    capacity: 1034,
    id: 153,
  },
  {
    name: "A-5-4",
    capacity: 1220,
    id: 154,
  },
  {
    name: "A-5-5",
    capacity: 1645,
    id: 155,
  },
  {
    name: "A-5-6",
    capacity: 1157,
    id: 156,
  },
  {
    name: "A-5-7",
    capacity: 1657,
    id: 157,
  },
  {
    name: "A-5-8",
    capacity: 1828,
    id: 158,
  },
  {
    name: "A-5-9",
    capacity: 916,
    id: 159,
  },
  {
    name: "A-5-10",
    capacity: 1056,
    id: 1510,
  },
  {
    name: "A-5-11",
    capacity: 1221,
    id: 1511,
  },
];

export const MAP_N8_ZONES = [
  {
    name: "Z-1-1",
    capacity: 7520,
    id: 2611,
  },
  {
    name: "Z-1-2",
    capacity: 7520,
    id: 2612,
  },
  {
    name: "Z-1-3",
    capacity: 7520,
    id: 2613,
  },
  {
    name: "Z-1-4",
    capacity: 7520,
    id: 2614,
  },
  {
    name: "Z-1-5",
    capacity: 7520,
    id: 2615,
  },
  {
    name: "Z-1-6",
    capacity: 7520,
    id: 2616,
  },
  {
    name: "Z-1-7",
    capacity: 7520,
    id: 2617,
  },
  {
    name: "Z-1-8",
    capacity: 7520,
    id: 2618,
  },
];

export const MAP_N9_ZONES = [
  {
    name: "Z-2-1",
    capacity: 9326,
    id: 2621,
  },
  {
    name: "Z-2-2",
    capacity: 9326,
    id: 2622,
  },
  {
    name: "Z-2-3",
    capacity: 9326,
    id: 2623,
  },
  {
    name: "Z-2-4",
    capacity: 9326,
    id: 2624,
  },
  {
    name: "Z-2-5",
    capacity: 9326,
    id: 2625,
  },
  {
    name: "Z-2-6",
    capacity: 9326,
    id: 2626,
  },
  {
    name: "Z-2-7",
    capacity: 9326,
    id: 2627,
  },
  {
    name: "Z-2-8",
    capacity: 9326,
    id: 2628,
  },
  {
    name: "Z-2-9",
    capacity: 9326,
    id: 2629,
  },
  {
    name: "Z-2-10",
    capacity: 9326,
    id: 26210,
  },
];

export const MAP_N10_ZONES = [
  {
    name: "Z-3-1",
    capacity: 17786,
    id: 2631,
  },
  {
    name: "Z-3-2",
    capacity: 17786,
    id: 2632,
  },
  {
    name: "Z-3-3",
    capacity: 17786,
    id: 2633,
  },
  {
    name: "Z-3-4",
    capacity: 17786,
    id: 2634,
  },
  {
    name: "Z-3-5",
    capacity: 17786,
    id: 2635,
  },
  {
    name: "Z-3-6",
    capacity: 17786,
    id: 2636,
  },
  {
    name: "Z-3-7",
    capacity: 17786,
    id: 2637,
  },
  {
    name: "Z-3-8",
    capacity: 17786,
    id: 2638,
  },
  {
    name: "Z-3-9",
    capacity: 17786,
    id: 2639,
  },
  {
    name: "Z-3-10",
    capacity: 17786,
    id: 26310,
  },
];

export const MAP_N11_ZONES = [
  {
    name: "Z-4-1",
    capacity: 9680,
    id: 2641,
  },
  {
    name: "Z-4-2",
    capacity: 9680,
    id: 2642,
  },
  {
    name: "Z-4-3",
    capacity: 9680,
    id: 2643,
  },
  {
    name: "Z-4-4",
    capacity: 9680,
    id: 2644,
  },
  {
    name: "Z-4-5",
    capacity: 9680,
    id: 2645,
  },
  {
    name: "Z-4-6",
    capacity: 9680,
    id: 2646,
  },
  {
    name: "Z-4-7",
    capacity: 9680,
    id: 2647,
  },
  {
    name: "Z-4-8",
    capacity: 9680,
    id: 2648,
  },
  {
    name: "Z-4-9",
    capacity: 9680,
    id: 2649,
  },
  {
    name: "Z-4-10",
    capacity: 9680,
    id: 26410,
  },
  {
    name: "Z-4-11",
    capacity: 9680,
    id: 26411,
  },
  {
    name: "Z-4-12",
    capacity: 9680,
    id: 26412,
  },
  {
    name: "Z-4-13",
    capacity: 9680,
    id: 26413,
  },
];

const ZONES = [
  ...MAP_N1_ZONES,
  ...MAP_N2_ZONES,
  ...MAP_N3_ZONES,
  ...MAP_N4_ZONES,
  ...MAP_N5_ZONES,
  ...MAP_N6_ZONES,
  ...MAP_N7_ZONES,
  ...MAP_N8_ZONES,
  ...MAP_N9_ZONES,
  ...MAP_N10_ZONES,
  ...MAP_N11_ZONES,
];
const URL = "/production/makkah";

export const initData = async () => {
  const reference = ref(firebaseDb, URL);

  const data = ZONES.reduce(
    (acc, zone) => ({
      ...acc,
      [zone.id]: {
        capacity: zone.capacity,
        inside: Math.floor(Math.random() * (zone.capacity + 1)),
        name: zone.name,
      },
    }),
    {},
  );

  await set(reference, data);
};

export type ZoneData = {
  name: string;
  capacity: number | null;
  id: number;
  inside: number | null;
};

export type MapData = {
  zones: { name: string; capacity: number; id: number }[];
  url: string;
  caption: string;
};

type FirebaseZoneData = Record<number, Omit<ZoneData, "id">>;

export const useZones = ({ zoneIds, token }: { zoneIds: number[]; token?: string }): ZoneData[] => {
  const [data, setData] = useState<FirebaseZoneData>({});
  const prevToken = usePrevious(token);
  const isConnectedRef = useRef(false);

  // Logic to keep firebase alive
  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      stopListening();
    }
    startListening();
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) {
        startListening();
      }
    }, DISCONNECT_CHECK_INTERVAL);
    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, []);

  const url = URL;

  const startListening = () => {
    isConnectedRef.current = true;
    const countRef = ref(firebaseDb, url);

    onValue(
      countRef,
      (snapshot) => {
        const val: Maybe<FirebaseZoneData> = snapshot.val();
        if (isDefined(val)) {
          setData(val);
        }
      },
      (err) => {
        console.log(err);
        isConnectedRef.current = false;
      },
    );
  };

  const stopListening = () => {
    off(ref(firebaseDb, url));
    isConnectedRef.current = false;
  };

  return zoneIds.map((zoneId) => (data[zoneId] ? { ...data[zoneId], id: zoneId } : { name: "", capacity: null, inside: null, id: zoneId }));
};

export const updateZone = async (zoneId: number, value: number, type: "capacity" | "inside") => {
  const reference = ref(firebaseDb, `${URL}/${zoneId}/${type}`);
  await set(reference, value);
};
