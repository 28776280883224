import { useMemo } from "react";

export type ScaleItem = {
  value: [number, number];
  zoneColor: string;
  textColor: string;
};

export const useScale = (
  scale: ScaleItem[],
  values: (number | string | undefined)[],
  fallbackColors = { zoneColor: "transparent", textColor: "white" },
): { value: number | string; zoneColor: string; textColor: string }[] => {
  // Memoize the sorted scale
  const sortedScale = useMemo(() => [...scale].sort((a, b) => a.value[0] - b.value[0]), [scale]);

  return values.map((value) => {
    if (value === undefined) {
      return { value: "-", ...fallbackColors };
    }

    const numValue = Number(value);
    if (isNaN(numValue)) {
      return { value: "-", ...fallbackColors };
    }

    // Binary search
    let left = 0;
    let right = sortedScale.length - 1;

    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const [min, max] = sortedScale[mid].value;

      if (numValue >= min && numValue <= max) {
        return { ...sortedScale[mid], value };
      }

      if (numValue < min) {
        right = mid - 1;
      } else {
        left = mid + 1;
      }
    }

    return { value, ...fallbackColors };
  });
};
